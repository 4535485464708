import inboxIllustration from './templates/inbox-illustration.html';
import savingsIllustration from './templates/savings-illustration.html';
import savingsGoalIllustration from './templates/savings-goal-illustration.html';
import acpAnytimeAlertsEnrollIllustration from './templates/acp-anytime-alerts-enroll-illustration.html';
import bankVerifyIllustration from './templates/bank-verify-illustration.html';
import moneyTransferIllustration from './templates/money-transfer.html';
import submitTransferIllustration from './templates/submit-transfer.html';
import virtualCardsMarketingIllustration from './templates/virtual-cards-marketing-illustration.html';
import bankTransferPointOneIllustration from './templates/bank-transfer-point-one-illustration.html';
import bankTransferPointTwoIllustration from './templates/bank-transfer-point-two-illustration.html';
import bankTransferPointThreeIllustration from './templates/bank-transfer-point-three-illustration.html';
import howchecksWorkDesktop from './templates/how-checks-work-desktop-illustration.html';
import howchecksWorkMobile from './templates/how-checks-work-mobile-illustration.html';
import bankTransferIllustration from './templates/bank-transfer-illustration.html';
import noOffersYetIllustration from './templates/no-offers-yet-illustration.html';
import generalErrorIllustration from './templates/general-error-illustration.html';
import doublearrowIllustration from './templates/doublearrow-illustration.html';
import noHassleIllustration from './templates/no-hassle-illustration.html';
import noWaitingIllustration from './templates/no-waiting-illustration.html';
import noCostIllustration from './templates/no-cost-illustration.html';
import lockIllustration from './templates/lock-illustration.html';


export class AcpIllustrationCtrl {
  constructor() {
    'ngInject';
  }
}

export const acpIllustrationComponent = {
  controller: AcpIllustrationCtrl,
  controllerAs: 'vm',
  /* @ngInject */ template($element, $attrs) {
    switch ($attrs.name) {
      case 'savings-illustration':
        return savingsIllustration;
      case 'savings-goal-illustration':
        return savingsGoalIllustration;
      case 'acp-anytime-alerts-enroll-illustration':
        return acpAnytimeAlertsEnrollIllustration;
      case 'inbox-illustration':
        return inboxIllustration;
      case 'bank-verify-illustration':
        return bankVerifyIllustration;
      case 'money-transfer-illustration':
        return moneyTransferIllustration;
      case 'submit-transfer-illustration':
        return submitTransferIllustration;
      case 'virtual-cards-marketing-illustration':
        return virtualCardsMarketingIllustration;
      case 'bank-transfer-point-one-illustration':
        return bankTransferPointOneIllustration;
      case 'bank-transfer-point-two-illustration':
        return bankTransferPointTwoIllustration;
      case 'bank-transfer-point-three-illustration':
        return bankTransferPointThreeIllustration;
      case 'how-checks-work-desktop-illustration':
        return howchecksWorkDesktop;
      case 'how-checks-work-mobile-illustration':
        return howchecksWorkMobile;
      case 'bank-transfer-illustration':
        return bankTransferIllustration;
      case 'no-offers-yet-illustration':
        return noOffersYetIllustration;
      case 'general-error-illustration':
        return generalErrorIllustration;
      case 'doublearrow-illustration':
        return doublearrowIllustration;
      case 'no-hassle-illustration':
        return noHassleIllustration;
      case 'no-waiting-illustration':
        return noWaitingIllustration;
      case 'no-cost-illustration':
        return noCostIllustration;
      case 'lock-illustration':
        return lockIllustration
      default:
        break;
    }
  }
};

export default savingsIllustration;
